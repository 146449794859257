/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_appsync_graphqlEndpoint": "https://zzzjo2i2izh65lhzscr6vnldba.appsync-api.us-east-1.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-1",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_user_files_s3_bucket": "mobius-evo-userfiles142032-evodeva",
    "aws_user_files_s3_bucket_region": "us-east-1",
    "aws_cognito_identity_pool_id": "us-east-1:cba05f8a-687c-40c4-9511-a0d2a4eb2fea",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_GtMXAv2Cz",
    "aws_user_pools_web_client_id": "22rt0jngof40n60s2sb74ftl5e",
    "oauth": {},
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL",
        "NICKNAME"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ]
};


export default awsmobile;

import React, { useContext } from "react";
import { AuthContext } from "../../Contexts";
import awsExports from '../../aws-exports'

function User() {
    const cognitoPayload = useContext(AuthContext).cognitoPayload;

    function Authenticated() {
        if (awsExports.aws_user_files_s3_bucket && awsExports.aws_user_files_s3_bucket_region) {
            const dashboardName = 'evoInfo' + awsExports.aws_user_files_s3_bucket.split('userfiles131353')[1];
            return <a 
                target="_blank"
                href={`https://console.aws.amazon.com/cloudwatch/home?region=${awsExports.aws_user_files_s3_bucket_region}#dashboards:name=${dashboardName}`}>
                Monitor Möbius Evolver data usage
            </a>
        }
    }
    return (
        <div>
            <h1>Welcome, {cognitoPayload.nickname}!</h1>
            <Authenticated/>
        </div>
    );
}

export default User;
